import script from "./Login.vue?vue&type=script&lang=ts&setup=true"
export * from "./Login.vue?vue&type=script&lang=ts&setup=true"

import "./Login.vue?vue&type=style&index=0&id=f66d35ec&lang=scss"
/* custom blocks */
import block0 from "./locale/Login.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=C%3A%5CJenkins%5Cworkspace%5CPlatformIdentity_hotfix_3.25.1%5Cfrontend%5Clogin%5Csrc%5Cviews%5CLogin.vue&lang=yaml&external"
if (typeof block0 === 'function') block0(script)


const __exports__ = script;

export default __exports__